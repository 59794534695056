import React from 'react';

function App() {
  return (
        <>
            <section>
              <div>
                  <div className="wave"></div>
                  <div className="wave"></div>
                  <div className="wave"></div>
              </div>

              <div className="App">
                  <div className="flex flex-col items-center justify-center h-screen px-4 text-center">
                      <h1 className="text-7xl font-bold mb-4 text-white max-md:text-4xl">En approooooche</h1>
                      <p className="text-xl mb-8 text-white">Nous travaillons d'arrache-pied pour vous offrir un site web extraordinaire. Restez à
                          l'affût !</p>
                      <div className="flex space-x-4">
                      </div>
                  </div>|
              </div>
          </section>
        </>
  );
}

export default App;